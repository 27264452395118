import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import AIItem from "./pages/Home/AIItem";
import General from "./pages/Home/General";

function App() {

    return (
        <Routes>
            <Route path="/" element={<Home/>}>
                <Route path="" element={<General/>}/>
                <Route path="/ai" element={<AIItem/>}/>
            </Route>
        </Routes>
    );
}

export default App;
