import React from 'react'
import s from './minLoader.module.css'

 const MinLoader = ({
     size =24
                    })=>{
    return (
        <div className={s.container}>
            <div className={s["lds-ring"]}
                 style={{
               width: size,
                     height: size,
            }}>
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    )
}

export default MinLoader
