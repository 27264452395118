import React from "react";
import * as Icons from "feather-icons-react";
import * as I from "../../assets/icons";

export const Icon = ({
                         name,
                         fill,
                         color,
                         size = 18,
                         feather = false,
                         className = "",
                         ...props
                     }) => {

    if (!name) {
        return "invalid <Icon/> type";
    }
    name = name.charAt(0).toUpperCase() + name.slice(1);

    if (feather) {
        const Feather = Icons[name];
        if (fill) {
            return (
                <Feather
                    className={className}
                    fill={fill}
                    color={color}
                    size={size}
                    {...props}
                />
            );
        }
        return (
            <Feather
                className={className}
                color={color}
                size={size}
                {...props}
            />
        );
    }

    const Custom = I[name];

    if (fill) {
        return (
            <Custom
                className={className}
                width={size}
                height={size}
                fill={fill}
                stroke={color}
                {...props}
            />
        );
    }

    return (
        <Custom
            className={className}
            width={size}
            height={size}
            stroke={color}
            {...props}
        />
    );
};

export default Icon;
