import {configureStore} from '@reduxjs/toolkit';
import {
  userReducer,
  projectsReducer,
} from './projects';

const store = configureStore({
  reducer: {
    user: userReducer,
    projects:projectsReducer
  },
});

export default store;
