import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    data: [],
    pageCount : 0,
}

export const projectsSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAllProjects(state, action) {
            state.data = action.payload
        },
        setProject(state, action) {
            state.data.unshift(action.payload)
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        deleteItem(state, action) {
            state.data = state.data.filter(item => item.id !== action.payload)
        },
        setPageCount(state, action) {
            state.pageCount = action.payload ?
                Math.floor(action.payload / 10) :
                0
        }
    },
})

export const projectsActions = projectsSlice.actions;
export const projectsReducer = projectsSlice.reducer

