import React from 'react'
import s from './AIItem.module.css'
import Icon from "../../../core/Icon";
import aiInfo from '../../../assets/images/ai-info.png'
import rocketImg from '../../../assets/images/rocket.png'
import brainImg from '../../../assets/images/brain.png'
import usersImg from '../../../assets/images/users.png'
import searchImg from '../../../assets/images/search.png'

const AIItem = () => {

    return (
        <div className={s.container}>
            <div className={s.info}>
                <div className={'f32-700'}>Summarizebot</div>
                <div style={{margin: '10px 0'}}>AI tool categories</div>
                <div className={'f18-700'}>suumarizebot.com</div>
                <div className={s.grid}>
                    <div className={s.column}>
                        <Icon name={'Bookmark'} feather={true}/>
                        AI tool book mark
                        <span className={'f18-700'}>1345</span>
                    </div>
                    <div className={s.column}>
                        <Icon name={'Star'} feather={true}/>
                        AI tool review rating
                        <span className={'f18-700'}>4.5</span>
                    </div>
                    <div className={s.column}>
                        <Icon name={'DollarSign'} feather={true}/>
                        Pricing
                        <span className={'f18-700'}> $19.99/mo</span>
                    </div>
                    <div className={s.column}>
                        <Icon name={'Upload'} feather={true}/>
                        Instagram | Facebook
                    </div>
                </div>
            </div>
            <div style={{textAlign: 'center', padding: '60px 0'}}>
                <img src={aiInfo} alt="aiInfo"/>
                <div style={{margin: '20px 0 40px 0'}}>
                    powered by AI
                    <span className={'f18-700'}>AI</span>
                </div>
                <div className={'f32-700'}>About AI Tool</div>
                <div className={s.row}>
                    <img src={rocketImg} alt="rocketImg"/>
                    <div>
                        We apply state-of-the-art artificial intelligence, machine learning, natural language
                        processing and blockchain technologies to simplify complex processes and automate what
                        previously required human-level intelligence.
                    </div>
                </div>
                <div className={s.row}>
                    <img src={brainImg} alt="brainImg"/>
                    <div>
                        Our company developed and patented the most advanced AI analytical algorithms for legal,
                        consulting, financial, publishing, media, automotive, healthcare, pharmaceutical, hospitality,
                        travel and others industries.
                    </div>
                </div>
                <div className={s.row}>
                    <img src={usersImg} alt="usersImg"/>
                    <div>
                        SummarizeBot team at the moment focused on global scientific problems solving related to
                        abstractive summarization, question answering systems and predictive analytics for fintech
                        industry.
                    </div>
                </div>
                <div className={s.row}>
                    <img src={searchImg} alt="searchImg"/>
                    <div>
                        SummarizeBot team at the moment focused on global scientific problems solving related to
                        abstractive summarization, question answering systems and predictive analytics for fintech
                        industry.
                    </div>
                </div>
                <div className={'f18-700'}
                     style={{marginTop: 80}}
                >
                    Please click on this link
                </div>
                <div className={'f32-700'}
                     style={{
                         gap: '0 10px',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center'
                     }}
                >
                    <Icon name={'Hand'} size={30}/>
                    <span>summarizebot.com</span>
                </div>
            </div>
        </div>
    )
}

export default AIItem
