import React from "react";
import s from "./button.module.css";
import {NavLink} from "react-router-dom";
import MinLoader from "../MinLoader";
import classNames from "classnames";

const Button = React.forwardRef(
    (
        {
            label = "",
            className = "",
            children,
            activeClass = "",
            onClick,
            disabled = false,
            variant = "",
            size = "normal",
            link,
            style,
            isLoading = false,
            onDisabled,
            ...props
        },
        ref
    ) => {
        if (link) {
            return (
                <NavLink
                    to={link}
                    className={({isActive}) => {
                        return classNames(
                            s.container,
                            {[s['container_' + variant]]: variant},
                            className,
                            {[activeClass]: isActive}
                        )
                    }}
                    onClick={onClick}
                    disabled={disabled}
                    style={style}
                    {...props}
                >
                    {label}
                    {children}
                </NavLink>
            );
        }

        return (
            <div
                ref={ref}
                className={
                    classNames(
                        s.container,
                        {[s['container_' + variant]]: variant},
                        className
                    )
                }
                onClick={(e) => {
                    disabled ? onDisabled?.() : onClick?.(e);
                }}
                {...props}
                data-disabled={disabled}
                style={style}
            >
                {isLoading ? <MinLoader/> : <>
                    {label}
                    {children}
                </>}
            </div>
        );
    }
);

export default Button
