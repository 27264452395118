import React, {useState} from 'react'
import s from './Sidebar.module.css'
import logoShort from '../../assets/images/logo-short.png'
import logoLong from '../../assets/images/logo-long.png'
import Button from "../../core/Button";
import Icon from "../../core/Icon";
import classNames from "classnames";

const Sidebar = () => {
    const [show, setShow] = useState(false)

    return (
        <div className={classNames(s.container)}
             style={{
                 padding: `30px 0 30px 0`,
                 paddingRight: show ? 0 : 20
             }}>
            <div className={s.top}>
                <div className={s.logo}>
                    <img src={show ? logoShort : logoLong} alt="logo"/>
                </div>
                <Button className={classNames(
                    s.open_close_btn
                )}
                        style={{
                            right: show ? 0 : -20
                        }}
                        onClick={() => {
                            setShow(!show)
                        }}
                >
                    <Icon feather={true} name={show ? 'ChevronsRight' : 'ChevronsLeft'} color={'#2DC5CB'}/>
                </Button>
            </div>
            <div className={s.block}>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'LogOut'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Sign Up (Free)
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'Search'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Search
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'Compass'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Explore
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'Market'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Get Featured
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'EditPen'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Submit AI Tool
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'Users'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Join Our Community
                    </div>
                </Button>
                <Button className={s.btn}>
                    <div>
                        <Icon name={'Togather'}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        Let’s Work Together
                    </div>
                </Button>
            </div>
            <div style={{display: 'flex', gap: !show ? 64 : 0, marginTop: 200}}>
                <Button className={s.btn}>
                    <div>
                        <div style={{
                            backgroundColor: 'rgba(0,0,0,.2)',
                            borderRadius: '50%',
                            width: 42,
                            height: 42,

                        }}/>
                    </div>
                    <div className={classNames(
                        s.title,
                        {[s.show_title]: show}
                    )}>
                        <div>Lara</div>
                        <div>Kroft</div>
                    </div>
                </Button>
                <Button className={classNames(
                    s.title,
                    {[s.show_title]: show}
                )}>
                    <Icon name={'Exit'} size={30}/>
                </Button>
            </div>
        </div>
    )
}

export default Sidebar
