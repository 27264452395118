import React from 'react'
import s from './home.module.css'
import classNames from "classnames";
import Input from "../../core/Input";
import Button from "../../core/Button";
import Icon from "../../core/Icon";
import avatarImg from '../../assets/images/avatar.png'
import whyImg from '../../assets/images/why.png'
import discoverImg from '../../assets/images/discover.png'
import Sidebar from "../../components/Sidebar";
import trendingImg from '../../assets/images/trending.png'
import botImg from '../../assets/images/bot.png'
import {Outlet} from "react-router-dom";

const Home = () => {

    return (<div className={s.container}>
        <Sidebar/>
        <div className={classNames(s.header, 'f18-700')}>
            The #1 AI Tools recommendation platform powerd by AI
        </div>
        <div style={{
            display: "flex",
            justifyContent: 'center',
            flexDirection: "column",
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 80
        }}>
            <div className={classNames('f32-700')}>
                AI Tools Recommendations Powered By AI
            </div>
            <div className={classNames('f16-400')}
                 style={{width: '670px', margin: "20px 0 60px 0 "}}
            >
                Describee the AI tool you are looking for, the task you want to complete or the goal you’re trying
                to achieve and receive precise AI tools suggestions and recommendation
            </div>
            <div style={{width: '100%'}}>
                <Input placeholder={'i want add originak royalty free music to my video reels'}
                       className={s.input_top}
                       containerClassName={s.input_top_container}
                       style={{padding: '0 10px'}}
                       frontIcon={() => {
                           return (<Icon name={'search'} feather={true} size={26} className={s.search_icon}/>)
                       }}
                       buttons={() => {
                           return (<Button label={'Show Recommendations'}
                                           className={classNames(s.show_btn, 'f18-700')}
                           />)
                       }}
                />
                <div className={s.light_blue_container} style={{margin: '30px 0 49px 0'}}>
                    <div style={{margin: '70px 0'}}>
                        <img src={avatarImg} alt="avatarImg" className={s.avatar}/>
                        <div className={classNames('f16-700')}>John Yokovich</div>
                        <div>Founder @Usethis.ai</div>
                    </div>
                    <div style={{width: 462, textAlign: 'start', display: "flex", alignItems: "center"}}>
                        Long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                        normal distribution of letters, as opposed to using 'Content here, content here', making it
                        look like readable English.
                    </div>
                    <div style={{height: '100%'}}>
                        <img src={whyImg} alt="whyImg" width={200} height={143}/>
                    </div>
                </div>
            </div>
        </div>
        <div className={s.category_container}>
            <div>
                <img src={discoverImg} alt=""/>
                <div className={'f32-700'}>Explore categories</div>
                <div>

                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button label={'See More'} variant={'outlined'}/>
                </div>
            </div>
        </div>
        <Outlet/>
        <div className={s.footer}>
            <div className={'f32-700'} style={{marginBottom: 30}}>🚀 Join our AI Newsletter Community!</div>
            <div>Get weekly updated on the latest AI tools, news and opionions from the world of AI innovation</div>
            <div>Signup now and stay ahead with Usethis.am</div>
            <Input placeholder={'Enter your email'}
                   className={s.footer_input}
                   containerClassName={s.footer_input_container}
                   style={{padding: '0 10px'}}
                   buttons={() => {
                       return (<Button label={'Subscribe'}
                                       className={classNames(s.footer_submit, 'f18-700')}
                       />)
                   }}
            />
        </div>
    </div>)
}

export default Home
