import React, {useEffect, useRef, useState} from 'react'
import regex from './regex'
import s from './input.module.css'
import Icon from '../Icon'
import classNames from "classnames";

const TYPING_INTERVAL = 240 //time in ms (240 miliseconds)

const validateField = (fieldName, text) => text?.length ? regex[fieldName].validation.test(text) : true

const Input = ({
                   value = '',
                   validationKey = null,
                   placeholder = '',
                   label,
                   name,
                   onFinish,
                   onChange,
                   multiline = false,
                   disabled = false,
                   errorText,
                   require = false,
                   className,
                   legend,
                   style,
                   buttons,
                   variant = 'outlined',
                   containerClassName,
                   containerStyle,
                   frontIcon = null,
                   type = 'text'
               }) => {
    const [visibility, setVisibility] = useState(validationKey === 'password')
    const [defaultValue, setDefaultValue] = useState(value)
    const [isValid, setIsValid] = useState(true)
    let typingTimer = useRef()

    useEffect(() => {
        return () => clearTimeout(typingTimer.current)
    }, [])

    const onTextChange = (e) => {
        setDefaultValue(e.target.value)

        if (validationKey) {
            // [--- handled when user finished typing >>>
            const _isValid = validateField(validationKey, e.target.value)
            setIsValid(_isValid)

            if (onFinish) {
                clearTimeout(typingTimer.current)
                return (typingTimer.current = setTimeout(() => {
                    onFinish(e, label, _isValid)
                }, TYPING_INTERVAL))
            }
            return onChange && onChange(e, label, _isValid)
        }
        // [--- handled when user finished typing >>>
        if (onFinish) {
            clearTimeout(typingTimer.current)
            return (typingTimer.current = setTimeout(() => {
                onFinish(e, label)
            }, TYPING_INTERVAL))
        }
        return onChange && onChange(e, label)
    }

    return (
        <label className={classNames(s.container, containerClassName)}
               style={containerStyle}
        >
            {label && <div className={classNames(s.label, {[s.error]: errorText !== undefined})}>
                <span>{label}</span>
                {require && <span className={classNames(s.require_star)}>*</span>}
            </div>}
            <fieldset tabIndex={0}
                      style={{paddingRight: validationKey === 'password' ? 12 : 0, ...style}}
                      className={classNames(
                          s.input,
                          s['input_' + variant],
                          {[s.disabled]: disabled},
                          {[s.error]: errorText !== undefined || !isValid},
                          className
                      )}
            >
                {legend && <legend className={classNames(s.legend)}>{legend}</legend>}
                {frontIcon?.({value: defaultValue, setValue: setDefaultValue})}
                {multiline ?
                    <textarea
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={onTextChange}
                        rows={6}
                    >
                    </textarea> :
                    <input
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={onTextChange}
                        type={validationKey === 'password' ? (visibility ? 'password' : 'text') : type}
                    />}
                {validationKey === 'password' && defaultValue.length > 0 &&
                    <div onClick={() => {
                        setVisibility(!visibility)
                    }}
                         style={{display: "flex", alignItems: 'center'}}
                    >
                        <Icon name={visibility ? 'EyeOff' : 'Eye'} feather={true}/>
                    </div>}
                {buttons?.({value: defaultValue, setValue: setDefaultValue})}
            </fieldset>
            {errorText ?
                <div className={s.error_text}>
                    {errorText}
                </div> :
                validationKey && !isValid && (
                    <div className={s.error_text}>
                        <Icon name={'AlertCircle'} feather={true} size={26} className={s.alert_icon}/>
                        {regex[validationKey].errorMessage}
                    </div>
                )}
        </label>
    )
}


export default Input
