import React from 'react'
import s from './General.module.css'
import trendingImg from "../../../assets/images/trending.png";
import botImg from "../../../assets/images/bot.png";
import Icon from "../../../core/Icon";
import Button from "../../../core/Button";

const General = ()=>{

    return (
        <>
            <div className={s.trending_container}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <img src={trendingImg} alt=""/>
                </div>
                <div className={'f32-700'}
                     style={{textAlign: 'center', marginBottom: 70}}
                >
                    Featured
                </div>
                <div className={s.trending_block}>
                    {
                        Array.from({length: 6}, (_, k) => ({
                            id: k + 1,
                        })).map(item => {
                            return (
                                <div key={item.id} className={s.trending_block_card}>
                                    <img src={botImg} alt="botImg"/>
                                    <div style={{
                                        gap: '10px 0',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <div className={'f18-700'}>Summarizebot</div>
                                        <div>ai category</div>
                                        <div>$ 150</div>
                                        <div className={s.row}>
                                            <div>
                                                <Icon name={'bookmark'} stroke={'#2DC5CB'} feather={true}/>
                                                1345
                                            </div>
                                            <div>
                                                <Icon name={'star'} stroke={'#2DC5CB'} feather={true}/>
                                                4.5
                                            </div>
                                        </div>
                                        <Button label={'View this AI'}
                                                variant={'outlined'}
                                                style={{marginTop: 18}}
                                                className={s.trending_block_card_btn}
                                                link={'/ai?id=' + item.id}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={s.just_launched_container}>
                <div className={'f32-700'} style={{textAlign: 'center', marginBottom: 70}}>Just Launched</div>
                <div className={s.trending_block}>
                    {
                        Array.from({length: 6}, (_, k) => ({
                            id: k + 1,
                        })).map(item => {
                            return (
                                <div key={item.id} className={s.trending_block_card}>
                                    <img src={botImg} alt="botImg"/>
                                    <div style={{
                                        gap: '10px 0',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <div className={'f18-700'}>Summarizebot</div>
                                        <div>ai category</div>
                                        <div>$ 150</div>
                                        <div className={s.row}>
                                            <div>
                                                <Icon name={'bookmark'}
                                                      stroke={'#2DC5CB'}
                                                      feather={true}/>
                                                1345
                                            </div>
                                            <div>
                                                <Icon name={'star'}
                                                      stroke={'#2DC5CB'}
                                                      feather={true}/>
                                                4.5
                                            </div>
                                        </div>
                                        <Button label={'View this AI'}
                                                variant={'outlined'}
                                                style={{marginTop: 18}}
                                                className={s.trending_block_card_btn}
                                                link={'/ai?id='+ item.id}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default General
