import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    data: null,
}

export const userSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUserData(state, action) {
            state.data = action.payload
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setUpdateUser(state, action) {
            state.data = action.payload
        },

    },
})

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer

